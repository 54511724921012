<template>
    <k-object-selector
        :value="value"
        :label="label"
        @input="changed"
        :itemFunc="itemFunc"
        :valueSelector="valueSelector"
        :suggestionsFunc="suggestionsFunc"
        objectName="institution"
        objectNamePlural="institutions"
    >
        <template slot="item" slot-scope="{item}">
            <v-list-item-avatar>
              <v-img :src="item.meta._image_url" style="background-color: white"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title>{{ item.meta._name }} <code>{{ item.code }}</code></v-list-item-title>
                <v-list-item-subtitle>{{ item.id }}</v-list-item-subtitle>
            </v-list-item-content>
        </template>
    </k-object-selector>
</template>

<script>
import api from '@/services/api';

export default {
  props: ['value', 'label'],
  methods: {
    changed(value) {
      this.$emit('input', value);
    },
    itemFunc(code) {
      return new Promise((resolve, reject) => {
        api.kernel.get(`/banking_institutions?code=${code}`).then((data) => {
          resolve(data.data[0]);
        }, () => {
          reject();
        });
      });
    },
    valueSelector(value) {
      return value.id;
    },
    suggestionsFunc(search) {
      if (!search) {
        return api.kernel.get('/banking_institutions').then((data) => data.data);
      }
      return Promise.all([
        api.kernel.get(`/banking_institutions/?code=${search}`).then((data) => [data], () => []),
      ]).then((datas) => [].concat(...datas));
    },
  },

};
</script>
